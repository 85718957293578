/**
 * This should evaluate to true for the contact us page in all languages.
 */

export default (): Ref<boolean> => {
  const route = useRoute()

  const isLandingPage = computed(
    (): boolean =>
      !!(route.name && typeof route.name === 'string' && route.name.includes('lp-uid')),
  )
  return isLandingPage
}
